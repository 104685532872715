<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <h3>{{ client.name }}</h3>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-btn color="primary" :to="{ name: 'listClients' }"
          >Elegir otro cliente</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col><v-divider></v-divider></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          v-model="select"
          :items="fechas"
          item-text="name"
          item-value="name"
          label="Seleccionar año"
          persistent-hint
          return-object
          single-line
          v-on:change="getDetail($route.params.clientId, $event.name)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <!-- <h4>Product Lineup</h4> -->
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          fixed
          top
          right
          style="margin-top: 15em"
          :disabled="selectedItems.length === 0"
          @click="viewStatistics"
          >Ver estadisticas</v-btn
        >
        <statistics-dialog
          :key="compKey"
          v-model="statisticsDialog"
          :types="selectedItemsCopy"
          :models="modelsSelected"
          :years="years"
        ></statistics-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="primary--text">
          Si desea ordenar una columna, de click sobre la misma.
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selectedItems"
          :headers="headersReport"
          :items="report"
          show-select
          disable-pagination
          sort-by="name"
          hide-default-footer
        >
          <template v-slot:item.margen="{ item }">
            {{ item.margen }}%
          </template>
          <template v-slot:item.facturacion="{ item }">
            {{ item.facturacion | toCurrency }}
          </template>
          <template v-slot:item.contribution="{ item }">
            {{ item.contribution | toCurrency }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StatisticsDialog from "@/views/Pages/product_performance/clients/components/StatisticsDialog";
export default {
  name: "Detail",
  components: { StatisticsDialog },
  data: () => ({
    compKey: 0,
    cliente: "",
    select: { name: "2022" },
    fechas: [{ name: "2021" }, { name: "2022" }, { name: "2023" }],
    statisticsDialog: false,
    selectedItems: [],
    modelsSelected: "",
    selectedItemsCopy: [],
    years: "",
    client: {},
    report: [],
    headersReport: [
      {
        text: "Modelo",
        value: "name",
      },
      {
        text: "Facturación",
        value: "facturacion",
      },
      {
        text: "Margen",
        value: "margen",
      },
      {
        text: "Contribución",
        value: "contribution",
      },
    ],
  }),
  mounted() {
    this.cliente = this.$route.params.clientId;
    this.getDetail(this.$route.params.clientId, "2022");
  },
  methods: {
    viewStatistics() {
      this.selectedItemsCopy = this.selectedItems;
      this.years = this.select.name;
      this.modelsSelected = this.selectedItemsCopy
        .map((e) => e.name.trim())
        .join(", ");

      this.selectedItems = [];
      this.statisticsDialog = true;
      this.compKey += 1;
    },
    getDetail(code, year) {
      console.log("ssssssssssssssssssss");
      console.log(year);
      this.$store.dispatch("working", true);
      this.$http

        .get("client/" + code + "/" + year)
        .then((res) => {
          this.client = res.data.client;
          this.report = res.data.report;
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.$toast.error(error.response.data.msg);
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
  },
};
</script>

<style scoped></style>
